.margin-none {
    margin: 0 !important;
}

.margin-auto {
    margin: auto;
}

.margin-top-none {
    margin-top: 0 !important;
}

.margin-top-4 {
    margin-top: 4px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-top-16 {
    margin-top: 16px !important;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-24 {
    margin-top: 24px;
}

margin-top-30 {
    margin-top: 30px;
}

.margin-top-32 {
    margin-top: 32px;
}

.margin-bottom-none {
    margin-bottom: 0 !important;
}

.margin-bottom-4 {
    margin-bottom: 4px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-12 {
    margin-bottom: 12px;
}

.margin-bottom-16 {
    margin-bottom: 16px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-bottom-32 {
    margin-bottom: 32px;
}

.margin-bottom-48 {
    margin-bottom: 48px;
}

.margin-left-none {
    margin-left: 0 !important;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-16 {
    margin-left: 16px;
}

.margin-left-32 {
    margin-left: 32px;
}

.margin-left-64 {
    margin-left: 64px;
}

.margin-right-4 {
    margin-right: 4px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-16 {
    margin-right: 16px;
}

.margin-right-32 {
    margin-right: 32px;
}

.margin-right-64 {
    margin-right: 64px;
}

.margin-y-16 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.pull-right {
    margin-left: auto;
}