@import './color';

.company-table-fullwidth {
    width: 100%;
    border: 0;
    border-collapse: collapse;

    td, th {
        border-bottom: 1px solid $gray-2;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 2px;
        padding-right: 2px;
    }

    &.with-padding-right {
        td, th {
            padding-right: 10px;
        }
    }

    &.cols-odd-even-bg {
        td:nth-child(odd), th:nth-child(odd) {
            background: $white;
        }
    }
}

.col-financial-heading {
    width: 300px;
    border-right: 1px solid $gray-3;
}

tr.col1-shadow {
    td:first-of-type {
        box-shadow: 10px 0 20px -20px inset $gray-5;
    }
}
